import { useFormAndValidation } from "../Hooks/useFormAndValidation";
import "../styles/PopupWithForm.css";
import { useEffect } from "react";
import PopupWithForm from "./PopupWithForm";

const LoginPopup = ({
  isOpen,
  handleActiveModalClose,
  handleLogin,
  handleOpenSignupPopup,
}) => {
  const {
    values,
    handleChange,
    errors,
    isValid,
    isTyping,
    resetForm, 
    isLoading,
  } = useFormAndValidation();

  useEffect(() => {
    if (isOpen) {
      resetForm(); 
    }
  }, [isOpen, resetForm]);
  
  const handleFormSubmit = (e) => {
    e.preventDefault(); 
    handleLogin(values.email, values.password) 
      .catch((error) => { 
        console.error('Login failed:', error);
        alert('Login failed. Please try again.');
      });
  };
  
  return (
    <PopupWithForm
      buttonText="Sign in"
      title="Sign in"
      isOpen={isOpen}
      handleActiveModalClose={handleActiveModalClose}
      onSubmit={handleFormSubmit}
    >
      <label
        className="popup__label"
      >
        Email
        <input
          name="email"
          className={`popup__input ${isTyping.email ? 'popup__input--active' : ''} ${
            errors.email ? 'popup__input--invalid' : ''
          }`}
          type="email"
          id="email"
          placeholder="Enter email"
          value={values.email || ""}
          onChange={handleChange}
          minLength="2"
          maxLength="40"
          required
        />
        {errors.email && <p className="popup__input--invalid">{errors.email}</p>}
      </label>

      <label
        className="popup__label"
      >
        Password
        <input
          name="password"
          className={`popup__input ${isTyping.password ? 'popup__input--active' : ''} ${
            errors.password ? 'popup__input--invalid' : ''
          }`}
          type="password"
          id="password"
          placeholder="Enter password"
          value={values.password || ""}
          onChange={handleChange}
          minLength="2"
          maxLength="16"
          required
        />
        {errors.password && <p className="popup__input--invalid">{errors.password}</p>}
      </label>
      <div className="popup__submit-btn-container">
        <button
          type="submit"
          className={`popup__submit-btn ${isValid ? "popup__submit-btn--active" : ""}`}
          disabled={!isValid || isLoading}
        >
         {isLoading ? "Signing in..." : "Sign In"}
        </button>
        <button
          type="button"
          className="popup__option-btn"
          onClick={handleOpenSignupPopup}
        >
        <span className="popup__option-or">Or </span>
        <span className="popup__option-secondary">Sign up</span>
        </button>
      </div>
    </ PopupWithForm>
  );
};

export default LoginPopup;