import React from "react";

import "../styles/Preloader.css";
export default function Preloader() {
  return (
    <div className="preloader">
      <div className="preloader__circle"></div>
    </div>
  );
}
